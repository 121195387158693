<template>
  <CCard>
    <CCardHeader>
      <CIcon name="cil-spreadsheet" />
      {{ $t("role.role.tab_info.card_address.title") }}
    </CCardHeader>
    <CCardBody>
      <TB :tb_config="tb">
        <e-columns>
          <e-column
            field="id"
            headerText="ID"
            type="number"
            :isPrimaryKey="true"
            is-identity="true"
            :allowEditing="false"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="to"
            :headerText="$t('models.family_address.to')"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="address"
            :headerText="$t('models.family_address.address')"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="city"
            :headerText="$t('models.family_address.city')"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="postal_code"
            :headerText="$t('models.family_address.postal_code')"
            clipMode="EllipsisWithTooltip"
            foreignKeyValue="code"
            :dataSource="CapData"
            foreignKeyField="id"
            editType="dropdownedit"
            :edit="postal_codeParams"
          ></e-column>
          <e-column
            field="country"
            :headerText="$t('models.family_address.country')"
            clipMode="EllipsisWithTooltip"
            foreignKeyValue="name"
            :dataSource="CountryData"
            foreignKeyField="code"
            editType="dropdownedit"
            :edit="countryParams"
          ></e-column>
          <e-column
            field="default_ship"
            :headerText="$t('models.family_address.default_ship')"
            displayAsCheckBox="true"
            type="boolean"
            editType="booleanedit"
          ></e-column>

          <e-column
            field="default_bill"
            :headerText="$t('models.family_address.default_bill')"
            displayAsCheckBox="true"
            type="boolean"
            editType="booleanedit"
          ></e-column>
          <!-- <e-column
          field="productproducer_id"
          headerText="Prodotto"
          :allowEditing= 'false'
          :foreignKeyValue="'translations.' + this.$i18n.locale + '.name'"
          :dataSource="ProductData"
          foreignKeyField="id"
          editType= 'textedit'
          clipMode="EllipsisWithTooltip"
        ></e-column> -->
          <e-column
            field="ts_created"
            :headerText="$t('common.table.created')"
            type="dateTime"
            :format="tsFormat"
            :allowEditing="false"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="ts_modified"
            :headerText="$t('common.table.last_modified')"
            type="dateTime"
            :format="tsFormat"
            :allowEditing="false"
            clipMode="EllipsisWithTooltip"
          ></e-column>
        </e-columns>
      </TB>
    </CCardBody>
  </CCard>
</template>
<script>
import { GetDataManager } from "../../../ds/index";
import { Query } from "@syncfusion/ej2-data";
import { DropDownList } from "@syncfusion/ej2-dropdowns";
import TB from "../../../common/TB";
let countryElem, stateElem, countryObj, stateObj;
export default {
  components: {
    TB,
  },
  data() {
    return {
      tb: {
        data: GetDataManager("auth_faddress", [
          this.$store.state.role.family_id,
        ]),
        searchOptions: {
          fields: ["address", "city", "postal_code.code"],
        },
        editSettings: {
          allowEditing: true,
          allowAdding: true,
          allowDeleting: false,
          mode: "Normal",
        },
        toolbar: ["Add", "Edit", "Update", "Cancel"],
        toolbarOptions: [
          // {
          //   text: "Refresh",
          //   tooltipText: "Refresh data",
          //   prefixIcon: "e-expand",
          //   id: "refresh",
          // },
          "Add",
          "Edit",
          // "Delete",
          "Update",
          "Cancel",
          "Search",
          "ColumnChooser",
        ],
        reload_on_save: true,
      },
      // countryParams: {
      //   params: {
      //     allowFiltering: true,
      //     dataSource: GetDataManager("geo_country"),
      //     fields: { text: "name", value: "code" },
      //     query: new Query().where("status", "equal", 100),
      //     actionComplete: () => false,
      //   },
      // },
      countryParams: {
        create: () => {
          countryElem = document.createElement("input");
          return countryElem;
        },
        read: () => {
          return countryObj.value;
        },
        destroy: () => {
          countryObj.destroy();
        },
        write: (args) => {
          countryObj = new DropDownList({
            dataSource: GetDataManager("geo_country"),
            fields: { value: "code", text: "name" },
            query: new Query().where("status", "equal", 100),
            value: args.rowData.country,
            change: () => {
              stateObj.enabled = true;
              let tempQuery = new Query()
                .where("country_id", "equal", countryObj.value)
                .take(10);
              stateObj.query = tempQuery;
              stateObj.text = null;
              stateObj.dataBind();
            },
            placeholder: "Select a country",
            floatLabelType: "Never",
          });
          countryObj.appendTo(countryElem);
        },
      },
      // postal_codeParams: {
      //   params: {
      //     allowFiltering: true,
      //     dataSource: GetDataManager("geo_postalcode"),
      //     fields: { text: "code", value: "id" },
      //     query: new Query().where("country_id", "equal", "CH").take(10),
      //     actionComplete: () => false,
      //     // }
      //   },
      // },
      postal_codeParams: {
        create: () => {
          stateElem = document.createElement("input");
          return stateElem;
        },
        read: () => {
          return stateObj.value;
        },
        destroy: () => {
          stateObj.destroy();
        },
        write: (args) => {
          stateObj = new DropDownList({
            dataSource: GetDataManager("geo_postalcode"),
            fields: { value: "id", text: "code" },
            value: args.rowData.postal_code,
            enabled: args.rowData.postal_code > 0,
            query: new Query()
              .where("country_id", "equal", args.rowData.country)
              .take(10),
            placeholder: "Select a postal code",
            floatLabelType: "Never",
            allowFiltering: true,
            actionComplete: () => false,
          });
          stateObj.appendTo(stateElem);
        },
      },
      CountryData: GetDataManager("geo_country"),
      CapData: GetDataManager("geo_postalcode"),
      // Units: new DataManager([
      //   { value: 1, label: "Kg" },
      //   { value: 2, label: "gr" },
      //   { value: 3, label: "Litri" },
      //   { value: 4, label: "dl" },
      //   { value: 5, label: "cl" },
      //   { value: 6, label: "ml" },
      // ]),
      tsFormat: { type: "dateTime", format: "dd/MM/yyyy HH:mm:ss" },
    };
  },
};
</script>

<template>
  <div :class="config.size_value">
    <span
      v-if="!config.form.active || config.fconfig.readonly"
      v-html="config.form.formData[config.fname]"
    />
    <div v-if="config.form.active && !config.fconfig.readonly">
      <ckeditor
        :editor="CkEditor"
        v-model="config.form.formData[config.fname]"
        :config="editorConfig"
      ></ckeditor
      ><br />
      <small class="form-text text-muted w-100">{{
        config.fconfig.description
      }}</small>
    </div>
  </div>
</template>

<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
  name: "FieldText",
  props: ["config"],
  components: { ckeditor: CKEditor.component },
  data() {
    return {
      CkEditor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            "undo",
            "redo",
            "|",
            "bold",
            "italic",
            "|",
            "numberedList",
            "bulletedList",
            "|",
            "link",
          ],
        },
      },
    };
  },
};
</script>

<template>
  <FormCard v-if="role" :config="general_data" @DataSaved="UpdateData" />
  <div v-else class="loading">
    <CSpinner color="secondary" size="lg" />
  </div>
</template>
<script>
import { GetDataManager } from "../../../ds/index";
import FormCard from "../../../common/form/Card";

export default {
  model: {
    prop: "role",
    event: "change",
  },
  props: ["role"],
  // data(){
  //   return {
  //     role: null
  //   }
  // },
  components: {
    FormCard,
  },
  // created() {
  //   let self = this;
  //   // Update propduct Info
  //   ApiCall(
  //     GetDataManager(
  //       "auth_frole",
  //       [],
  //       this.$store.state.role.id
  //     ),
  //     new Query(),
  //     function (e) {
  //       // self.pp = e.result;
  //       self.role = e.result;
  //     }
  //   );
  // },
  // watch: {
  //   "pp.category__macrocategory_id": function (nV, oV) {
  //     console.log("changed macrocategory", nV, oV);
  //     // this.UpdateMacrocategoryOptions();
  //     this.pp.category__parent_id = null;
  //     // this.UpdateLtypeOptions();
  //     this.pp.ptype_id = null;
  //   },
  //   "pp.category__parent_id": function (nV, oV) {
  //     console.log("changedparent", nV, oV);
  //     // this.UpdateCategoryOptions();
  //     this.pp.category_id = null;
  //   },
  // },
  methods: {
    UpdateData(data) {
      // console.log("UpdateData")
      // this.role = data
      this.$emit("change", data);
    },
  },
  computed: {
    general_data() {
      return {
        title: this.$t("role.role.tab_info.card_condition.title"),
        data: this.role,
        fields: {
          "producer.info_condition": {
            title: this.$t("models.producer.info_condition"),
            type: "CKInput",
            size: 12,
            subsize: [2, 10],
            description: this.$t("models.producer.info_condition_info"),
            enabled: [1, 2, 3, 5].includes(this.role.r_type),
          },
          "market.producer_condition": {
            title: this.$t("models.market.producer_condition"),
            type: "CKInput",
            size: 12,
            subsize: [2, 10],
            description: this.$t("models.market.producer_condition_info"),
            enabled: [1, 5].includes(this.role.r_type),
          },
          "market.island_condition": {
            title: this.$t("models.market.island_condition"),
            type: "CKInput",
            size: 12,
            subsize: [2, 10],
            description: this.$t("models.market.island_condition_info"),
            enabled: [1, 5].includes(this.role.r_type),
          },
          "market.family_condition": {
            title: this.$t("models.market.family_condition"),
            type: "CKInput",
            size: 12,
            subsize: [2, 10],
            description: this.$t("models.market.family_condition_info"),
            enabled: [1, 5].includes(this.role.r_type),
          },
          "island.info_market": {
            title: this.$t("models.island.info_market"),
            type: "CKInput",
            size: 12,
            subsize: [2, 10],
            description: this.$t("models.island.info_market_info"),
            enabled: [1, 3, 4, 5].includes(this.role.r_type),
          },
          "island.info_family": {
            title: this.$t("models.island.info_family"),
            type: "CKInput",
            size: 12,
            subsize: [2, 10],
            description: this.$t("models.island.info_family_info"),
            enabled: [1, 3, 4, 5].includes(this.role.r_type),
          },
          "island.equipment": {
            title: this.$t("models.island.equipment"),
            type: "CKInput",
            size: 12,
            subsize: [2, 10],
            description: this.$t("models.island.equipment_info"),
            enabled: [1, 3, 4, 5].includes(this.role.r_type),
          },
          "island.business_hours": {
            title: this.$t("models.island.business_hours"),
            type: "CKInput",
            size: 12,
            subsize: [2, 10],
            description: this.$t("models.island.business_hours_info"),
            enabled: [1, 3, 4, 5].includes(this.role.r_type),
          },
          "island.terms_of_delivery": {
            title: this.$t("models.island.terms_of_delivery"),
            type: "CKInput",
            size: 12,
            subsize: [2, 10],
            description: this.$t("models.island.terms_of_delivery_info"),
            enabled: [1, 3, 4, 5].includes(this.role.r_type),
          },
        },
        dm: GetDataManager("auth_frole", []),
      };
    },
  },
};
</script>

<template>
  <CCard>
    <CCardHeader>
      {{ $t("role.role.tab_wallet.card_wallet.title") }}
      <b>{{ wl.get_currency_display }}</b>
      <div class="card-header-actions">
        <CButton
          variant="outline"
          color="primary"
          size="sm"
          :pressed.sync="dataForm.active"
          ><CIcon name="cil-pencil" />&nbsp;{{
            $t("common.editor.button.edit")
          }}</CButton
        >
      </div>
    </CCardHeader>
    <CCardBody>
      <div class="bd-example">
        <dl class="row">
          <dd class="col-sm-2">
            {{ $t("models.family_wallet.iban") }}
          </dd>
          <dt class="col-sm-4">
            <span v-if="!dataForm.active">{{ dataForm.body.iban }}</span>
            <CInput
              v-if="dataForm.active"
              :description="$t('models.family_wallet.iban_info')"
              horizontal
              v-model="dataForm.body.iban"
              :is-valid="'iban' in dataForm.errors ? false : null"
              :invalid-feedback="
                'iban' in dataForm.errors
                  ? dataForm.errors.iban.join('<br>')
                  : null
              "
            />
          </dt>

          <dd class="col-sm-2">
            {{ $t("models.family_wallet.bic") }}
          </dd>
          <dt class="col-sm-4">
            <span v-if="!dataForm.active">{{ dataForm.body.bic }}</span>
            <CInput
              v-if="dataForm.active"
              :description="$t('models.family_wallet.bic_info')"
              horizontal
              v-model="dataForm.body.bic"
              :is-valid="'bic' in dataForm.errors ? false : null"
              :invalid-feedback="
                'bic' in dataForm.errors
                  ? dataForm.errors.bic.join('<br>')
                  : null
              "
            />
          </dt>

          <dd class="col-sm-2">
            {{ $t("models.family_wallet.pay_freq") }}
          </dd>
          <dt class="col-sm-4">
            <span v-if="!dataForm.active">{{
              GetFtype(dataForm.body.pay_freq)
            }}</span>
            <CSelect
              v-if="dataForm.active"
              :description="$t('models.family_wallet.pay_freq_info')"
              horizontal
              :is-valid="'pay_freq' in dataForm.errors ? false : null"
              :invalid-feedback="
                'pay_freq' in dataForm.errors
                  ? dataForm.errors.pay_freq.join('<br>')
                  : null
              "
              :value.sync="dataForm.body.pay_freq"
              :options="options"
            />
          </dt>

          <dd class="col-sm-2">
            {{ $t("models.family_wallet.wallet_min") }}
          </dd>
          <dt class="col-sm-4">
            <span v-if="!dataForm.active">{{ dataForm.body.wallet_min }}</span>
            <CInput
              v-if="dataForm.active"
              :description="$t('models.family_wallet.wallet_min_info')"
              horizontal
              v-model="dataForm.body.wallet_min"
              :is-valid="'wallet_min' in dataForm.errors ? false : null"
              :invalid-feedback="
                'wallet_min' in dataForm.errors
                  ? dataForm.errors.wallet_min.join('<br>')
                  : null
              "
            />
          </dt>
        </dl>
      </div>
    </CCardBody>
    <CCardFooter v-if="dataForm.active">
      <vue-ladda
        :loading="dataForm.loading"
        data-style="zoom-in"
        button-class="btn btn-primary btn-sm"
        @click.prevent="SaveData"
        ><CIcon name="cil-save" />&nbsp;{{
          $t("common.editor.button.save")
        }}</vue-ladda
      >
    </CCardFooter>
  </CCard>
</template>

<script>
import { DataManager } from "@syncfusion/ej2-data";
import DrfAdaptor from "../../../http/EJ2_adapter";
import { apiUrl } from "../../../http";

export default {
  name: "RoleWallet",
  props: ["wl"],
  data() {
    console.log("WL:", this.wl);
    return {
      dataForm: {
        loading: false,
        active: false,
        body: {
          id: "",
          iban: "",
          bic: "",
          pay_freq: "",
          wallet_min: "",
        },
        errors: {},
      },
      options: [
        {
          value: "2",
          label: this.$t("models.family_wallet.pay_freq_option2"),
        },
      ],
    };
  },
  mounted() {
    this.dataForm.body.id = this.wl.id;
    this.dataForm.body.iban = this.wl.iban;
    this.dataForm.body.bic = this.wl.bic;
    this.dataForm.body.pay_freq = this.wl.pay_freq;
    this.dataForm.body.wallet_min = this.wl.wallet_min;
  },
  computed: {
    role() {
      // console.log(this.$auth.user());
      return this.$store.state.role;
    },
  },
  methods: {
    GetFtype(ftype) {
      for (var i = 0; i < this.options.length; i++) {
        if (this.options[i].value == ftype) {
          return this.options[i].label;
        }
      }
      return ftype;
    },
    SaveData() {
      // console.log("save_data")
      // console.log(this.dataForm.body)
      let dm = new DataManager({
        url: apiUrl(
          `/api/auth/family/${this.$store.state.role.family_id}/wallet/`
        ),
        adaptor: new DrfAdaptor(),
        crossDomain: true,
      });
      this.dataForm.loading = true;
      this.dataForm.errors = {};
      // console.log(this.$store.state.role.id)
      console.log(this.dataForm.body);
      dm.update("", this.dataForm.body).then(
        (e) => {
          // console.log("after update");
          // console.log(e);
          let role = this.role;
          role.family = e;
          this.$store.commit("setRole", role);
          this.dataForm.loading = false;
          this.dataForm.active = false;
          this.$store.dispatch("toaster/add", {
            title: this.$t("user.profile.data.toast.ok.title"),
            text: this.$t("user.profile.data.toast.ok.message"),
            autohide: true,
          });
        },
        (reason) => {
          let errors = JSON.parse(reason[0].error.response);
          this.dataForm.errors = errors;
        }
      );
    },
  },
};
</script>
